import {initializeApp} from 'firebase/app';
import {getAnalytics, logEvent, setUserProperties} from 'firebase/analytics';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: 'AIzaSyA7PWudeECTp4HmcPM0hHuQ0snCLdKOQks',
	authDomain: 'defitbet.firebaseapp.com',
	projectId: 'defitbet',
	storageBucket: 'defitbet.appspot.com',
	messagingSenderId: '621363997994',
	appId: '1:621363997994:web:5dfe6f0f7c3ebbe6108253',
	measurementId: 'G-ME1K5MPHC9',
	debug_mode: true
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Analytics and get a reference to the service
const analytics = getAnalytics(app);

export const logEvents = obj => {
	console.log(analytics);
	logEvent(analytics, obj);
};

export const logEventWithCustomCategory = (key, value) => {
	//logEvent(key, value?.user_id);
	//analytics.logEvent(key, value);
	console.log("?key?", key, "?value?", value);
	logEvent(analytics, key, value);
	  
	// logEvent(analytics, key);
}


// //logEvent('select_content', {
// 	content_type: 'image',
// 	content_id: 'P12453',
// 	items: [{ name: 'Kittens' }]
//   });

export const logUserProperty = obj => {
	// console.log(analytics);
	setUserProperties(analytics, {user_id: obj?._id, user_name: obj?.userName});

	// logEvent(analytics, obj);
};
