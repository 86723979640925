import React from 'react';
import {
	ImageBackground,
	StyleSheet,
	TextInputProps,
	TouchableOpacity
} from 'react-native';
import ExpoFastImage from 'expo-fast-image';
import icons from '../assets/icon';

interface Props extends TextInputProps {
	onBtnPress?: () => void;
	isBtnDisable?: boolean;
}

const RefreshButton: React.FC<Props> = props => {
	const {onBtnPress, isBtnDisable} = props;
	return (
		<TouchableOpacity
			style={styles.container}
			onPress={onBtnPress}
			disabled={isBtnDisable}>
			<ImageBackground style={styles.bgReloadBtn} source={icons.bg_reload}>
				<ExpoFastImage
					style={styles.reloadBtn}
					source={icons.ic_reload}
					resizeMode={'contain'}
				/>
			</ImageBackground>
		</TouchableOpacity>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1
	},
	bgReloadBtn: {
		height: 48,
		width: 48,
		justifyContent: 'center',
		alignItems: 'center'
	},
	reloadBtn: {
		height: 25,
		width: 21
	}
});

export default RefreshButton;
