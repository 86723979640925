const ScreenNames = {
	Login: 'Login',
	HomeScreen: 'HomeScreen',
	SignUpScreen: 'SignupScreen',
	SplashScreen: 'SplashScreen',
	FeedScreen: 'FeedScreen',
	LiveStreamingScreen: 'LiveStreamingScreen',
	WalletScreen: 'WalletScreen',
	DiscoverScreen: 'DiscoverScreen',
	ProfileScreen: 'ProfileScreen',
	CMSScreen: 'CMSScreen',
	ApplyFilterScreen: 'ApplyFilterScreen',

	ProfileSetupScreen: 'ProfileSetupScreen',
	BottomTabScreen: 'BottomTabScreen',
	FeedsRouter: 'FeedsRouter',
	LiveTabRoutes: 'LiveTabRoutes',
	WalletTabRoutes: 'WalletTabRoutes',
	DiscoverRouter: 'DiscoverRouter',
	ProfileRouter: 'ProfileRouter',
	SettingsScreen: 'SettingsScreen',
	WhoCanViewScreen: 'WhoCanViewScreen',
	FaqScreen: 'FaqScreen',

	ExportPrivateKeyScreen: 'ExportPrivateKeyScreen',
	WalletStatsScreen: 'WalletStatsScreen',
	FollowingFollowersScreen: 'FollowingFollowersScreen',

	WalletDepositScreen: 'WalletDepositScreen',
	WalletWithdrawalScreen: 'WalletWithdrawalScreen',
	OtherUserProfileScreen: 'OtherUserProfileScreen',
	BetsCategoryScreen: 'BetsCategoryScreen',
	EventDetailsScreen: 'EventDetailsScreen',
	JoinBetCreateScreen: 'JoinBetCreateScreen',
	NotificationScreen: 'NotificationScreen',
	createPredictionMarket: 'CreatePredictionMarket',
	ViewDisputeScreen: 'ViewDisputeScreen',
	DisputeThankYouScreen: 'DisputeThankYouScreen',
	ReplicateBetCreatScreen: 'ReplicateBetCreatScreen',
	FeedFilterScreen: 'FeedFilterScreen',
	DiscoverFindFriendsScreen: 'DiscoverFindFriendsScreen',
	ChatListScreen: 'ChatListScreen',
	ChatUserSuggestion: 'ChatUserSuggestion',
	ChatDetailsScreen: 'ChatDetailsScreen',
	UserViewProfileScreen: 'UserViewProfileScreen',
	MyBetListScreen: 'MyBetListScreen',
	TransakWebView: 'TransakWebView',
	OpenDisputeScreen: 'OpenDisputeScreen',
	SupportDetailsScreen: 'SupportDetailsScreen',
	SupportTicketsList: 'SupportTicketsList',
	OpenDisputeInfoScreen: 'OpenDisputeInfoScreen',
	DisputeResultScreen: 'DisputeResultScreen',
	StoryShareScreen: 'StoryShareScreen',
	BetMakerResultScreen: 'BetMakerResultScreen',
	EditProfileScreen: 'EditProfileScreen',
	JuryIntroScreen: 'JuryIntroScreen',
	JuryPayChargeScreen: 'JuryPayChargeScreen',
	JuryCongratulationScreen: 'JuryCongratulationScreen',
	RecoverFundsScreen: 'RecoverFundsScreen',
	AfterJuryScreen: 'AfterJuryScreen',
	MoreAboutStrikePolicyScreen: 'MoreAboutStrikePolicyScreen',
	BetDetailsScreen: 'BetDetailsScreen',
	BetRevealResultScreen: 'BetRevealResultScreen',
	CustomBetDetailsScreen: 'CustomBetDetailsScreen',
	PredictionMarketsDetailsScreen: 'PredictionMarketsDetailsScreen',
	PushNotificationScreen: 'PushNotificationScreen',
	CameraPage: 'CameraPage',
	MediaPage: 'MediaPage',
	VideoCreationScreen: 'VideoCreationScreen',
	VideoContentScreen: 'VideoContentScreen',
	ReferralProgramScreen: 'ReferralProgramScreen',
	WithdrawSuccessScreen: 'WithdrawSuccessScreen',
	UserEngagementScreen: 'UserEngagementScreen',
	LiveChallengeScreen: 'LiveChallengeScreen',
	LiveChallengeListScreen: 'LiveChallengeListScreen'
};

export default ScreenNames;
