import React, {useEffect, useState} from 'react';
import {TouchableOpacity, View, Text, Platform} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';
import ButtonGradient from '../../../components/ButtonGradient';
import UserEngagementComponent from '../../../components/UserEngagementComponent';
import Strings from '../../../constants/strings';
import colors from '../../../theme/colors';
import {defaultTheme} from '../../../theme/defaultTheme';
import {gradientColorAngle} from '../../../theme/metrics';
import styles from './style';
import * as WebBrowser from 'expo-web-browser';
import {useSelector} from 'react-redux';
import {RootState} from '../../../redux/store';
import * as Crypto from 'expo-crypto';
import {widgetBaseUrl} from '../../../constants/api';
import icons from '../../../assets/icon';
import ScreenNames from '../../../navigation/screenNames';
import {useNavigation, useRoute} from '@react-navigation/native';
import ExpoFastImage from 'expo-fast-image';
import InformationPopUpView from '../../../components/InformationPopUpView';
import {getWalletStatics} from '../../../redux/apiHandler/apiActions';
import { logEventWithCustomCategory } from '../../../../firebaseConfig';

const UserEngagementScreen = () => {
	const userInfo = useSelector((state: RootState) => {
		return state.userInfo.data;
	});
	const navigation = useNavigation();
	const params = useRoute().params;

	const [buyCoinWthCardUrl, setBuyCoinWthCardUrl] = useState('');
	const [modalNeedHelpVisible, setModalNeedHelpVisible] = useState(false);
	const [walletStatics, setWalletStatics] = useState({});

	const callWalletStatsApi = async () => {
		const response = await getWalletStatics({days: 'all', viewDetails: false});
		console.log('getWalletStatics response :: ', response?.data);
		setWalletStatics(response?.data);
	};

	useEffect(() => {
		callWalletStatsApi();
		getPaymentUrl();
	}, []);

	const getPaymentUrl = async () => {
		const walletAddress = userInfo?.user?.walletAddress;
		const cryptoHash = await Crypto.digestStringAsync(
			Crypto.CryptoDigestAlgorithm.SHA512,
			walletAddress + 'c46e24ab6b516ce5686bb97d5a6d38a8'
		);

		console.log(
			"walletAddress + 'c46e24ab6b516ce5686bb97d5a6d38a8'",
			cryptoHash
		);

		let webUrl =
			widgetBaseUrl +
			'f49448ba-2a9b-438e-8bb6-fdbdb30f5818' +
			'&type=' +
			Strings.buy +
			'&currencies=' +
			'MATIC' +
			'&return_url=' +
			Strings.defibetHouseUrl +
			'&address=' +
			walletAddress +
			'&signature=' +
			cryptoHash;
		setBuyCoinWthCardUrl(webUrl);
		// return webUrl;
	};

	function onBtnSkipForP2p() {
		navigation.navigate(ScreenNames.BetsCategoryScreen);
	}
	const onBtnSkipForShortVideo = () => {
		params?.pickVideoFromGallery();
	};
	const onBtnSkipForLiveChallenge = () => {
		navigation.navigate(ScreenNames.LiveChallengeScreen);
	};

	const p2pBetData = {
		title: Strings.create_a_challenge,
		description1: Strings.engage_your_audience_and_earn_up_to,
		description2: Strings.betting_fees_generated,
		highlightedText: '50%',
		image: icons.star_congrats
	};
	const liveChallenge = {
		title: Strings.create_a_live_challenge,
		description1: Strings.earn_up_to,
		description2: Strings.from_streaming_content,
		highlightedText: '20%',
		image: icons.star_congrats
	};

	const shortVideoData = {
		title: Strings.create_a_video_with_bet_attached,
		description1: Strings.engage_your_audience_and_earn_up_to,
		description2: Strings.betting_fees_generated,
		highlightedText: '50%',
		image: icons.star_congrats
	};

	return (
		<>
			<SafeAreaView style={styles.container}>
				<View style={styles.container}>
					<UserEngagementComponent
						data={
							params?.isForP2pBet
								? p2pBetData
								: params?.isForVideoCreation
								? shortVideoData
								: liveChallenge
						}
					/>
					{/* {params?.isForP2pBet && (
						<Text style={styles.amountInfoTextStyle}>
							{walletStatics?.minWalletBalanceString}
						</Text>
					)} */}

					<View style={styles.buttonView}>
						<TouchableOpacity
							onPress={() => {
								setModalNeedHelpVisible(true);
							}}
							style={styles.helpButton}
							hitSlop={{top: 10, bottom: 10, left: 10, right: 10}}>
							<ExpoFastImage
								resizeMode={'contain'}
								source={icons.about}
								style={styles.helpImg}
							/>
						</TouchableOpacity>

						<ButtonGradient
							onPress={async () => {
								logEventWithCustomCategory(Strings.firebaseEventKeys.user_buy_crypto, {
									user_id: userInfo?.user?._id,
								})
								await WebBrowser.openBrowserAsync(buyCoinWthCardUrl);
							}}
							colorArray={defaultTheme.secondaryGradientColor}
							angle={gradientColorAngle}
							buttonTextcolor={colors.white}
							buttonText={Strings.top_up_with_credit_card}
							style={styles.nextButton}
						/>

						<TouchableOpacity
							onPress={() => {
								params?.isForP2pBet
									? onBtnSkipForP2p()
									: params?.isForVideoCreation
									? onBtnSkipForShortVideo()
									: onBtnSkipForLiveChallenge();
							}}>
							<Text style={styles.titleSkip}>{Strings.skip}</Text>
						</TouchableOpacity>
					</View>
				</View>

				<InformationPopUpView
					popupTitle={walletStatics?.mercuryoPaymentInfo?.title}
					buttonTitle={Strings.got_it}
					description={walletStatics?.mercuryoPaymentInfo?.content}
					onButtonPress={() => {
						setModalNeedHelpVisible(!modalNeedHelpVisible);
					}}
					isVisible={modalNeedHelpVisible}
					colorArray={defaultTheme.ternaryGradientColor}
				/>
			</SafeAreaView>
		</>
	);
};

export default UserEngagementScreen;
