import Smartlook from 'smartlook-react-native-wrapper';

export const initSmartLookSession = () => {
	// Smartlook.setRenderingMode(Smartlook.RenderingMode.Native);
	// Smartlook.setupAndStartRecording('cc32d6460d927752d832996f3e79446da571c048');
};

export const trackUserIdentification = (userIdentifier: string) => {
	// Smartlook.setUserIdentifier(userIdentifier, {});
};
