const icons = {
	//Login

	appLogo: require('../icon/appLogo.png'),
	google: require('../icon/google.png'),
	loginBg: require('../icon/loginBg.png'),
	facebook: require('../icon/facebook.png'),
	apple: require('../icon/apple.png'),
	metamask: require('../icon/metamask.png'),
	// demo: require('../icon/demo.png'),
	//Bottom Tab icons

	// create tab
	ic_create_bet: require('../icon/ic_create_bet.png'),

	//Discover tab
	discover_active: require('../icon/discover_active.png'),
	discover_inActive: require('../icon/discover_inActive.png'),

	//Feed tab
	feed_active: require('../icon/feed_active.png'),
	feed_inActive: require('../icon/feed_inActive.png'),

	//Video tab
	video_active: require('../icon/video_active.png'),
	video_inActive: require('../icon/video_inActive.png'),

	//Wallet tab
	wallet_active: require('../icon/wallet_active.png'),
	wallet_inActive: require('../icon/wallet_inActive.png'),

	//profile tab
	profile: require('../icon/profile.png'),
	profileBadge: require('../icon/profileBadge.png'),

	profile_active: require('../icon/profile_active.png'),

	unCheck: require('../icon/unCheck.png'),
	checkbox: require('../icon/checkbox.png'),
	calendar_today: require('../icon/calendar_today.png'),

	//Header tab
	ic_filter: require('../icon/ic_filter.png'),
	// menu: require('../icon/menu.png'),
	plusRed: require('../icon/plusRed.png'),
	send: require('../icon/send.png'),
	notification: require('../icon/notification.png'),
	back: require('../icon/back.png'),
	setting: require('../icon/setting.png'),
	plusRound: require('../icon/plusRound.png'),
	closeBlack: require('../icon/closeBlack.png'),
	upWhite: require('../icon/upWhite.png'),
	downGray: require('../icon/downGray.png'),
	celebration: require('../icon/celebration.png'),
	clipboard: require('../icon/clipboard.png'),
	smallGreenUpArrow: require('../icon/smallGreenUpArrow.png'),
	arrow_Top: require('../icon/arrow_Top.png'),
	right: require('../icon/right.png'),
	close: require('../icon/close.png'),
	userDummy: require('../icon/userDummy.png'),
	instagram: require('../icon/instagram.png'),
	twitter: require('../icon/twitter.png'),
	search: require('../icon/search.png'),
	// demoImg: require('../icon/demoImg.png'),
	plusWhite: require('../icon/plusWhite.png'),
	plusGradient: require('../icon/plusGradient.png'),

	Currency_Ethereum: require('../icon/Currency_Ethereum.png'),
	convertDownArrow: require('../icon/convertDownArrow.png'),
	// demo1: require('../icon/demo1.png'),
	ic_menu: require('../icon/ic_menu.png'),
	timer: require('../icon/timer.png'),
	telegram: require('../icon/telegram.png'),
	whatsApp: require('../icon/whatsApp.png'),
	copy: require('../icon/copy.png'),
	help: require('../icon/help.png'),
	time: require('../icon/time.png'),
	logout: require('../icon/logout.png'),
	notifications_gray: require('../icon/notifications_gray.png'),
	noInternet: require('../icon/noInternet.png'),
	share: require('../icon/share.png'),
	// soccerBg: require('../icon/soccerBg.jpeg'),
	ic_previous: require('../icon/ic_previous.png'),
	ic_next: require('../icon/ic_next.png'),
	camera: require('../icon/camera.png'),
	gallery: require('../icon/gallery.png'),
	leftGray: require('../icon/leftGray.png'),

	link: require('../icon/link.png'),
	imageExpander: require('../icon/image_expander.png'),
	playIcon: require('../icon/play.png'),
	//videoPlaceHolder: require('../icon/video_placeholder.png'),
	gallary: require('../icon/gallary.png'),
	photo_collection: require('../icon/photo_collections.png'),
	photo_camera: require('../icon/photo_camera.png'),
	delete: require('../icon/delete.png'),

	// basketball2: require('../icon/basketball2.png'),
	// soccerBG: require('../icon/unsplash_G1XGl0lPOak.png'),
	ic_chatSend: require('../icon/ic_chatSend.png'),
	ic_emoji: require('../icon/ic_emoji.png'),
	support: require('../icon/support.png'),
	emojiThinking: require('../icon/emojiThinking.png'),
	info: require('../icon/info_test.png'),
	joker: require('../icon/joker.png'),
	party: require('../icon/party.png'),
	snooker: require('../icon/snooker.png'),
	star: require('../icon/star.png'),
	unicorn: require('../icon/unicorn.png'),
	ic_refresh: require('../icon/ic_refresh.png'),
	ic_image: require('../icon/ic_image.png'),
	reply: require('../icon/reply.png'),
	editProfile: require('../icon/editProfile.png'),
	emojiSad: require('../icon/emoji_sad.png'),
	emojiNeutral: require('../icon/emoji_neutral.png'),

	arrowForward: require('../icon/arrow_forward.png'),
	medal: require('../icon/medal.png'),
	lock: require('../icon/lock.png'),
	star_congrats: require('../icon/star_congrats.png'),
	ellipse: require('../icon/ellipse.png'),
	whistle: require('../icon/whistle.png'),
	hammer: require('../icon/hammer.png'),
	ic_info: require('../icon/ic_info.png'),
	no_notification: require('../icon/no_notification.png'),
	no_message: require('../icon/no_message.png'),
	ic_timer: require('../icon/ic_timer.png'),
	ic_hot: require('../icon/ic_hot.png'),
	ic_friends: require('../icon/ic_friends.png'),
	walletConnect: require('../icon/walletConnect.png'),
	no_livestreaming: require('../icon/no_livestreaming.png'),
	splash_text: require('../icon/splashText.png'),
	no_followers: require('../icon/no_followers.png'),
	no_followings: require('../icon/no_followings.png'),
	no_friends: require('../icon/no_friends.png'),
	no_match: require('../icon/no_match.png'),
	no_prediction_market: require('../icon/no_prediction_market.png'),
	no_search: require('../icon/no_search.png'),
	no_wallet_stats: require('../icon/no_wallet_stats.png'),
	VS: require('../icon/VS.png'),
	no_feedfilter: require('../icon/no_feedfilter.png'),
	ic_all:
		'https://res.cloudinary.com/shivicloudinary/image/upload/v1662706449/ceta4rrgpdqsnecoz4vz.png',

	plusSquare: require('../icon/plusSquare.png'),
	arrow_up: require('../icon/arrow_up.png'),
	eventDammy: require('../icon/eventDammy.png'),
	share_gradient: require('../icon/share_gradient.png'),
	share_green_gradient: require('../icon/share_green_gradient.png'),
	ic_replicate_btn: require('../icon/ic_replicate_btn.png'),
	ic_qr_code_scanner: require('../icon/ic_qr_code_scanner.png'),
	about: require('../icon/about.png'),
	ic_new_message: require('../icon/ic_new_message.png'),
	currency: require('../icon/currency.png'),
	arrow_up_white: require('../icon/up_white.png'),
	arrow_down_white: require('../icon/down_white.png'),
	wallet: require('../icon/wallet.png'),
	ic_buy_crypto: require('../icon/ic_buy_crypto.png'),
	ic_report: require('../icon/ic_report.png'),
	ic_share_upload: require('../icon/ic_share_upload.png'),
	ic_send: require('../icon/ic_sendmessage.png'),
	ic_contact_green: require('../icon/search_person_green.png'),
	ic_checkbox: require('../icon/checkbox_gradiant.png'),
	ic_check_gradiant: require('../icon/gradiant_round_check.png'),
	emoji_amazing: require('../icon/emoji_amazing.png'),
	emoji_surprised: require('../icon/emoji_surprised.png'),
	emoji_wow: require('../icon/emoji_wow.png'),
	emoji_hey: require('../icon/emoji_hey.png'),
	emoji_omg: require('../icon/emoji_omg.png'),
	emoji_rocket: require('../icon/emoji_rocket.png'),
	emoji_blowout: require('../icon/emoji_blownout.png'),
	swipUp: require('../icon/swipUp.png'),
	flash_off: require('../icon/flash_off.png'),
	flip_camera_android: require('../icon/flip_camera_android.png'),
	night_mode: require('../icon/night_mode.png'),
	camera_shutter: require('../icon/camera_shutter.png'),
	flash_on: require('../icon/flash_on.png'),
	camera_record: require('../icon/camera_record.png'),
	delete_white: require('../icon/ic_delete.png'),
	video_thumb: require('../icon/video_thumb.png'),
	download: require('../icon/download.png'),
	imageBgSplashHolder: require('../icon/imageBgSplashHolder.png'),
	money_withdraw: require('../icon/money_withdraw.png'),
	up_gray: require('../icon/up_gray.png'),
	ic_follow_user: require('../icon/ic_follow_user.png'),
	ic_share_popup: require('../icon/ic_share_popup.png'),
	upload_Blue: require('../icon/upload_Blue.png'),
	ic_reload: require('../icon/ic_reload.png'),
	bg_reload: require('../icon/bg_reload.png'),
	truly_fun: require('../icon/truly_fun.png'),
	smaile: require('../icon/smaile.png')
};
export default icons;
