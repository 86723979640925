import React from 'react';
import {
	Image,
	StyleSheet,
	Text,
	TextInputProps,
	TouchableOpacity,
	View
} from 'react-native';
import icons from '../assets/icon';
import Strings from '../constants/strings';
import {Fonts, horizontalScale, verticalScale} from '../theme';
import colors from '../theme/colors';
import {moderateScale, width} from '../theme/metrics';
import Triangle from './Triangle';

interface Props extends TextInputProps {
	onPress?: () => void;
}

const InstallPWAPopUp: React.FC<Props> = props => {
	return (
		<TouchableOpacity onPress={props.onPress} style={styles.container}>
			<View style={styles.subContainer}>
				<Image
					resizeMode={'contain'}
					source={icons.appLogo}
					style={{height: 40, width: 40, marginHorizontal: 8}}
				/>
				<Text style={styles.textStyle}>
					{
						<>
							{Strings.install}
							<Text
								style={{
									fontFamily: Fonts.type.Inter_Bold
								}}>
								{Strings.app_name}
							</Text>
							{Strings.on_your_iPhone}

							<Image
								resizeMode={'contain'}
								source={icons.upload_Blue}
								style={{height: 20, width: 20, top: 2}}
							/>
							<Text>{Strings.tap_and_then_Add_to_Home_Screen}</Text>
						</>
					}
				</Text>
			</View>
			<Triangle style={{transform: [{rotate: '180deg'}]}} />
		</TouchableOpacity>
	);
};

const styles = StyleSheet.create({
	container: {
		alignItems: 'center',
		width: width,
		position: 'absolute',
		bottom: 0
	},
	subContainer: {
		backgroundColor: colors.white,
		borderRadius: 6,
		marginHorizontal: horizontalScale(8),
		flexDirection: 'row',
		alignItems: 'center'
	},
	textStyle: {
		fontSize: moderateScale(16),
		color: colors.black,
		fontFamily: Fonts.type.Inter_Regular,
		flexWrap: 'wrap',
		flex: 1,
		marginRight: horizontalScale(8),
		marginVertical: verticalScale(12),
		top: -4
	}
});

export default InstallPWAPopUp;
