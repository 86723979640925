import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
	StyleSheet,
	View,
	Image,
	TouchableOpacity,
	Alert,
	Platform,
	NativeSyntheticEvent,
	ImageLoadEventData,
	StatusBar
} from 'react-native';
// import Video, {LoadError, OnLoadData} from 'react-native-video';
import {Video} from 'expo-av';
import {useIsForeground} from '../CustomHooks/useIsForeground';
import type {NativeStackScreenProps} from '@react-navigation/native-stack';
import {useIsFocused} from '@react-navigation/core';
import icons from '../../assets/icon';
import ButtonGradient from '../ButtonGradient';
import Strings from '../../constants/strings';
import {colors} from 'react-native-elements';
import {defaultTheme} from '../../theme/defaultTheme';
import {
	gradientColorAngle,
	height,
	horizontalScale,
	SAFE_AREA_PADDING,
	screenHeight,
	verticalScale
} from '../../theme/metrics';
import ScreenNames from '../../navigation/screenNames';
import {StackActions} from '@react-navigation/native';
import {SafeAreaView, useSafeAreaInsets} from 'react-native-safe-area-context';
import {isIos} from '../../navigation/routes';
import {isSafari} from 'react-device-detect';
import useUpdateEffect from '../CustomHooks/useUpdateEffect';
import {useDispatch} from 'react-redux';
import {updateApiLoader} from '../../redux/reducerSlices/preLogin';
// import { LoadError } from 'react-native-video';

const isVideoOnLoadEvent = (
	event: OnLoadData | NativeSyntheticEvent<ImageLoadEventData>
): event is OnLoadData => 'duration' in event && 'naturalSize' in event;

const userAgentName = window.navigator.userAgent.toLowerCase();

type Props = NativeStackScreenProps<'MediaPage'>;
export function MediaPage({navigation, route}: Props): React.ReactElement {
	const {path, type, from} = route.params;
	const [hasMediaLoaded, setHasMediaLoaded] = useState(false);
	const [videoData, setVideoData] = useState({});
	const isForeground = useIsForeground();
	const isScreenFocused = useIsFocused();
	let isVideoPaused = !isForeground || !isScreenFocused;

	const [isVideoPlay, setIsVideoPlay] = useState(isVideoPaused);

	const [videoDuration, setVideoDuration] = useState();
	const [isCloseClick, setisCloseClick] = useState(false);
	const [isContinueClick, setIsContinueClick] = useState(false);

	const insets = useSafeAreaInsets();
	const dispatch = useDispatch();

	const onMediaLoad = useCallback(
		(event: OnLoadData | NativeSyntheticEvent<ImageLoadEventData>) => {
			if (isVideoOnLoadEvent(event)) {
				setVideoDuration(event.duration);
				console.log('Video Data.', event);
				console.log(
					`Video loaded. Size: ${event.naturalSize.width}x${event.naturalSize.height} (${event.naturalSize.orientation}, ${event.duration} seconds)`
				);
			}
		},
		[]
	);
	const onMediaLoadEnd = useCallback(() => {
		console.log('media has loaded.');
		setHasMediaLoaded(true);
	}, []);
	const onMediaLoadError = useCallback((error: LoadError) => {
		console.log(`failed to load media: ${JSON.stringify(error)}`);
	}, []);

	useEffect(() => {
		dispatch(updateApiLoader({apiLoader: true}));
		callVideoMetaData();
	}, [path]);

	useUpdateEffect(() => {
		if (isCloseClick) {
			setTimeout(() => {
				navigation.dispatch(StackActions.popToTop());
			}, 1000);
			return;
		}

		if (isContinueClick) {
			setTimeout(() => {
				navigation.navigate(ScreenNames.VideoCreationScreen, {
					path: path,
					type: type,
					from: from,
					videoDuration: parseInt(videoData?.duration)
				});
			}, 1000);

			return;
		}
	}, [isCloseClick, isContinueClick]);

	const screenStyle = useMemo(
		() => ({opacity: hasMediaLoaded ? 0 : 0}),
		[hasMediaLoaded]
	);

	const onTap = () => {
		setIsVideoPlay(!isVideoPlay);
	};

	const callVideoMetaData = async () => {
		const videoMetaData = await getVideoMetaData(event.target.files[0]);
		setVideoData(videoMetaData);
		setTimeout(() => {
			dispatch(updateApiLoader({apiLoader: false}));
		}, 200);
	};

	const getVideoMetaData = () =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = () => {
				const media = new Audio(reader.result);
				media.onloadedmetadata = () => resolve(media);
			};
			reader.readAsDataURL(path);
			reader.onerror = error => reject(error);
		});

	return (
		<SafeAreaView
			style={{flex: 1, backgroundColor: defaultTheme.secondaryBackGroundColor}}
			edges={['bottom', 'top']}>
			{videoData && !isVideoPaused && (
				<View
					style={{
						flex: 1,
						backgroundColor: defaultTheme.secondaryBackGroundColor,
						paddingBottom: verticalScale(isSafari ? 70 : 50)
					}}>
					<View
						style={{
							marginBottom: verticalScale(isSafari ? 50 : 0),
							backgroundColor: defaultTheme.secondaryBackGroundColor
						}}>
						<video
							autoPlay={true}
							loop={true}
							src={videoData.src}
							width="100%"
							style={{
								backgroundColor: defaultTheme.secondaryBackGroundColor,
								paddingBottom: verticalScale(70)
							}}
							height={
								isSafari
									? screenHeight -
									  (insets.bottom === 0 ? insets.bottom : insets.bottom + 8) -
									  100
									: screenHeight - verticalScale(140)
							}
							controls={isSafari}></video>
					</View>

					<TouchableOpacity
						style={[
							styles.closeButton,
							{
								top: isIos()
									? insets.top + 7
									: SAFE_AREA_PADDING.paddingTop + 25,
								left: isIos() ? insets.left + 15 : SAFE_AREA_PADDING.paddingLeft
							}
						]}
						onPress={() => {
							setIsVideoPlay(true);
							setisCloseClick(true);
						}}>
						<Image source={icons.back} style={styles.closeIcon} />
					</TouchableOpacity>
					<ButtonGradient
						style={styles.button(
							isIos()
								? insets.bottom === 0
									? insets.bottom + 8
									: 0
								: verticalScale(8)
						)}
						buttonText={Strings.continue}
						buttonTextcolor={colors.white}
						colorArray={defaultTheme.secondaryGradientColor}
						angle={gradientColorAngle}
						onPress={() => {
							if (videoData?.duration <= 3 || videoData?.duration > 15) {
								alert(Strings.upload_video_15s);
							} else {
								setIsVideoPlay(true);
								setIsContinueClick(true);
							}
						}}
					/>
				</View>
			)}

			{/* <ButtonGradient
				style={styles.button}
				buttonText={Strings.continue}
				buttonTextcolor={colors.white}
				colorArray={defaultTheme.secondaryGradientColor}
				angle={gradientColorAngle}
				onPress={() => {
					if (videoDuration <= 3) {
						Alert.alert('Alert', Strings.upload_video_15s, [
							{
								text: Strings.ok,
								onPress: () => console.log('Cancel Pressed')
							}
						]);
					} else {
						setIsVideoPlay(true);
						navigation.navigate(ScreenNames.VideoCreationScreen, {
							path: path,
							type: type,
							from: from
						});
					}
				}}
			/> */}
		</SafeAreaView>
	);
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: 'white',
		width: '100%'
	},
	closeButton: {
		position: 'absolute',
		top: SAFE_AREA_PADDING.paddingTop,
		left: SAFE_AREA_PADDING.paddingLeft,
		width: 50,
		height: 50
	},
	saveButton: {
		position: 'absolute',
		bottom: SAFE_AREA_PADDING.paddingBottom,
		left: SAFE_AREA_PADDING.paddingLeft,
		width: 40,
		height: 40
	},
	icon: {
		textShadowColor: 'black',
		textShadowOffset: {
			height: 0,
			width: 0
		},
		textShadowRadius: 1
	},
	closeIcon: {
		width: 20,
		height: 20
	},
	button: bottom => ({
		marginHorizontal: horizontalScale(20),
		borderRadius: 8,
		justifyContent: 'center',
		position: 'absolute',
		bottom: bottom,
		width: '90%',
		backgroundColor: defaultTheme.secondaryBackGroundColor
	})
});
