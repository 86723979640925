const colors = {
	black: '#000000',
	placeholderColor: '#FFFFFFB2',
	transparent: '#FFFFFF00',
	textTitle: 'rgba(255, 255, 255, 0.7)',
	white: '#FFFFFF',
	sunflowerYellow: '#FFD600',
	goldenTwo: '#F2CB00',
	blueGreySix: '#607D8D',
	blueGreySixWithOpacity: '#607D8B1A',
	blackwithOpacity: '#00000030',
	whiteFour10: '#FFFFFF4D',
	whiteFour20: '#FFFFFF33',
	whiteFour50: '#FFFFFF80',
	darkBlue: '#141434',
	blackFour: '#222222',
	cloudyBlueThree: '#B3BFCF',
	blueBlue: '#1948CB',
	primaryBg: '#F7F8FC',
	greyishBrownSix: '#555555',
	brownGreyTwo: '#808080',
	blackSeven: '#2D2D2D',
	blackTwo: '#00000066',
	coolGrey14: '#B5BBC4',
	paleGreyTwelve: '#E4E7F4',
	blue: '#E8ECF9',
	greyTwo: '#3F3F3F',
	handleColor: '#e0e0e0',
	coral: '#EF5350',
	corallight: '#345033',
	blueGreyFour: '#607D8B',
	red: '#FF4849',
	darkGray: '#616161',
	gray: '#CCCCCC',
	bluelight: '#0086B7',
	grayText: '#585858',
	grayLight: '#FAFAFA',
	grayLightText: '#818181',
	back: 'rgba(0, 0, 0,0.7)',
	backgroundTrns: '#4d383838',
	pinklight: '#FFE5E5',
	grayBg: '#F6F6F6',
	grayBgStreaks: '#efefef',
	blackTransparent: 'rgba(0, 0, 0, 1.0)',
	blackGradient: ['rgba(0, 0, 0, 1.0)', 'rgba(0, 0, 0, 1.0)'],
	oranglight: '#FFD8C4',
	greenLight: '#00DD9E',
	parrot: '#00FF19',
	greyLight: '#F5F5F5',
	selMsgColor: '#14D7FF',
	darkRed: '#FF0000',
	purple: '#BD00FF',
	yellow: '#FFE600',
	lightGreen: '#DEF9DE',
	offWhite: '#D8E0F0',
	chatTextColor: '#393939',
	lightOrange: '#949596',
	skyBlue: '#00C2FF',
	blackGray: '#262626',
	redTag: '#EE0041',
	progressStart: '#FF0000',
	textPink: 'rgba(212, 0, 107,1)',
	graytransparent: 'rgba(46, 49, 49, 0.4)',
	blackTransparent05: 'rgba(0, 0, 0, 0.5)',
	blackTransparent03: 'rgba(0, 0, 0, 0.3)',
	darkGray1: '#353535',
	whiteTransparent: 'rgba(255, 255, 255, 0.5)'
};

export default colors;
